import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'

import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import TwoCol from '../components/rows/twocol'

export default function Blog({data}) {

    let blog = data.strapiBlogs
    
    return (
        <Layout>
        <Seo title={blog.blog_intro.title} />
        <div className="blog">
            <Hero
                blog
                banner={blog.blog_banner.localFile.publicURL}
            />
            <Intro 
                blog
                title={blog.blog_intro.title}
            />
            <TwoCol 
                white
                column1={blog.blog_description.description_1}
                column2={blog.blog_description.description_2}
            />
            {blog.blog_intro2 ?
            <div className="blog-quote">            
                <Intro 
                    blog
                    title={blog.blog_intro2.title}
                />
            </div>
            : null }
            {blog.blog_description2 ?
            <TwoCol 
                white
                column1={blog.blog_description2.description_1}
                column2={blog.blog_description2.description_2}
            />
            : null }
        </div>
        </Layout>
    )
}

export const query = graphql`
    query Article($slug: String!)  {
    strapiBlogs(slug: {eq: $slug}) {
      id
      blog_banner {
        localFile {
          publicURL
        }
      }
      blog_intro {
        id
        title
      }
      blog_intro2 {
        id
        title
      }
      blog_description {
        id
        description_1
        description_2
      }
      blog_description2 {
        id
        description_1
        description_2
      }
    }
  }
  
`